import React from "react";
import styled from "styled-components";
import logo from "../assets/logopng.png";
import ASitesLogo from "../assets/ASitesLogo.png";
import { FaPhoneAlt, FaFacebook } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { RiInstagramFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = ({ theme }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="top">
        <img src={logo} className="logo" alt="" />
        <div className="right">
          <div className="links">
            <h3>Links</h3>
            <Link to={"/"} className="link-styles">
              <p>Home</p>
            </Link>
            <Link to={"/products"} className="link-styles">
              <p>{t("navProducts")}</p>
            </Link>
            <Link to={"/brands"} className="link-styles">
              <p>{t("navBrands")}</p>
            </Link>
            <Link to={"/contact-us"} className="link-styles">
              <p> {t("navContact")}</p>
            </Link>
          </div>
          <div className="contact">
            {/* <div>
              <FaPhoneAlt className="icon" />
              <h3>+383 44 123 123</h3>
            </div> */}
            <div>
              <IoMail className="icon" />
              <h3>artiola@coolestparfume.com</h3>
            </div>
            <div>
              <Link
                to={"https://www.instagram.com/coolest.parfume/"}
                target="_blank"
              >
                <RiInstagramFill className="icon" />
              </Link>
              <h3>@coolest.parfume</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <p>Designed and Developed by:</p>
        <Link
          to={"https://asitesagency.com/"}
          className="link-styles"
          target="_blank"
        >
          <img src={ASitesLogo} alt="" />
        </Link>
        <Link
          to={"https://asitesagency.com/"}
          className="link-styles"
          target="_blank"
        >
          <p>Visit ASites Agency</p>
        </Link>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 3em 4%;
  background: var(--primaryBackgroundColor);
  color: var(--fontPrimaryColor);
  box-shadow: 0 0 12px 1px var(--fontSecondaryColor);
  .top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
      width: 340px;
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4em;
      .links {
        display: flex;
        flex-direction: column;
        gap: 10px;
        h3 {
          font-weight: 500;
        }
        p {
          color: var(--fontSecondaryColor);
          font-weight: 300;
        }
      }
      .contact {
        display: flex;
        flex-direction: column;
        gap: 1em;
        div {
          display: flex;
          align-items: center;
          gap: 10px;
          .icon {
            font-size: 3em;
            background: var(--primaryColor);
            padding: 10px;
            border-radius: 10px;
            color: white;
          }
          h3 {
            font-weight: 300;
            color: var(--fontSecondaryColor);
          }
        }
        span {
          display: flex;
          gap: 10px;
          .icon {
            font-size: 3em;
            background: var(--primaryColor);
            padding: 10px;
            border-radius: 10px;
            color: white;
          }
        }
      }
    }
  }
  .bottom {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
      text-align: center;
    }
    img {
      width: 140px;
    }
  }
  @media (max-width: 700px) {
    .top {
      flex-direction: column;
      gap: 3em;
      align-items: center;
      .right {
        flex-wrap: wrap;
        div {
          flex-wrap: wrap;
        }
      }
    }
  }
`;

export default Footer;
