import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { motion } from "framer-motion";
import { useAuth } from "../../context/AuthContext";
import { db } from "../../firebase";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { CartContext } from "../../CartContext";
import { CiHeart, CiShoppingCart } from "react-icons/ci";
import { HiShoppingCart } from "react-icons/hi2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCartShopping } from "react-icons/fa6";

const Product = ({ item }) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [likedItems, setLikedItems] = useState([]);
  const cart = useContext(CartContext);

  useEffect(() => {
    const storedLikedItems = localStorage.getItem("likedItems");
    if (storedLikedItems) {
      setLikedItems(JSON.parse(storedLikedItems));
    }
  }, []);

  const productId = doc(db, "users", `${currentUser?.email}`);
  const isLiked = likedItems.includes(item.productId);

  const savePackage = async () => {
    if (currentUser?.email) {
      await updateDoc(productId, {
        savedProducts: arrayUnion({
          productId: item.productId,
          productName: item.productName,
          image: item.image,
        }),
      });
    } else {
      alert("Please log in to save a package");
      return;
    }

    const updatedLikedItems = isLiked
      ? likedItems.filter((likedId) => likedId !== item.productId)
      : [...likedItems, item.productId];

    setLikedItems(updatedLikedItems);
    localStorage.setItem("likedItems", JSON.stringify(updatedLikedItems));
  };

  const isInCart = cart.items.some((cartItem) => cartItem.id === item.id);

  return (
    <Container>
      <motion.div
        className="product"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
      >
        <Link to={`/products/${item.productName}`} className="link-styles">
          <img src={item.image} alt={item.productName} />
          <h3>{item.brand}</h3>
          <h2>{item.productName}</h2>
        </Link>
        <div className="priceDiv">
          {item.discountPrice ? (
            <span>
              <h2>€{item.price}</h2>
              <h1>€{item.discountPrice}</h1>
            </span>
          ) : (
            <h1>€{item.price}</h1>
          )}
        </div>

        <span>
          <Link to={`/products/${item.productName}`}>
            <button>See More</button>
          </Link>
          {!isInCart ? (
            <CiShoppingCart
              onClick={() => {
                cart.addOneToCart(item.id);
              }}
              className="icon"
              style={{
                fontSize: "32px",
                color: isInCart ? "#1f912e" : "#615c54",
              }} // Change color based on whether the item is in the cart
            />
          ) : (
            <FaCartShopping
              onClick={() => cart.removeOneFromCart(item.id)}
              className="icon"
              style={{ fontSize: "24px" }}
            />
          )}
        </span>
      </motion.div>
    </Container>
  );
};
const Container = styled.div`
  width: 270px;
  height: 300px;
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
  border-top: none;
  border-bottom: none;
  /* .img {
    position: relative;
    height: 100px; */
  /* .sale {
      position: absolute;
      top: 2%;
      right: 0;
      display: flex;
      justify-content: flex-end;
      text-align: end;
      font-weight: 300;
      font-size: 17px;
      color: black;
      padding: 10px 14px;
      background-color: #65bde662;
      backdrop-filter: blur(10px);
      border-radius: 20px;
    } */
  img {
    height: 150px;
    transition: 0.4s;
    /* &:hover {
      transform: scale(1.1);
    } */
  }

  .priceDiv {
    span {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      h2 {
        text-decoration: line-through;
        color: gray;
        font-weight: 300;
        font-size: 17px;
      }
    }
    h1 {
      font-weight: 500;
      font-size: 19px;
      color: var(--primaryColor);
    }
  }
  h3 {
    font-weight: 300;
    font-family: "Comfortaa";
    font-size: 16px;
    color: var(--primaryColor);
  }
  h2 {
    font-weight: 500;
    font-size: 20px;
  }
  h1 {
  }
  span {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    button {
      padding: 10px 24px;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      font-weight: 300;
      text-transform: uppercase;
      border: none;
      border-radius: 30px;
      background: #333;
      color: white;
      cursor: pointer;
      transition: 0.3s;
      box-shadow: 0 4px 12px 2px #b8b8;
      &:hover {
        background: #1f1f1f;
        color: white;
      }
    }
    .icon {
      display: flex;
      align-items: center;
      font-size: 2em;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        color: var(--primaryColor);
      }
    }
  }
  @media (max-width: 740px) {
    text-align: center;
    width: 47%;
    align-items: center;
    padding: 10px;
    .img {
      display: flex;
      justify-content: center;
      width: 97%;
      /* max-height: 17em; */
      object-fit: cover;
      img {
        width: 400px;
      }
    }
    /* span {
      justify-content: center;
      gap: 3px;
      .moreBtn {
        padding: 10px 9px;
        line-height: 1em;
        font-size: 10px;
      }
    } */
  }
`;

export default Product;
