import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { FaBars, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import logo from "../assets/logopng.png";
import LanguageSelector from "./LanguageSelector";
import { useAuth } from "../context/AuthContext";
import { CiShoppingCart } from "react-icons/ci";
import { CartContext } from "../CartContext";
import CartProduct from "./CartProduct";

const Navbar = () => {
  const cart = useContext(CartContext);
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => setToggle(!toggle);
  const [activeTab, setActiveTab] = useState("home");
  const location = useLocation();
  const pathname = location.pathname;
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [modal, setModal] = useState(false);
  const productsCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0
  );

  useEffect(() => {
    if (pathname === "/") {
      setActiveTab("home");
    } else if (pathname === "/products") {
      setActiveTab("products");
    } else if (pathname === "/brands") {
      setActiveTab("brands");
    } else if (pathname === "/contact-us") {
      setActiveTab("contact-us");
    } else if (pathname === "/about-us") {
      setActiveTab("about-us");
    }
  }, [pathname]);

  return (
    <Container>
      <Link to={"/"} className="link-styles">
        <img src={logo} alt="" className="logo" />
      </Link>
      <ul
        onClick={handleToggle}
        className={toggle ? "nav-menu active" : "nav-menu"}
      >
        <Link to={"/"} className="link-styles">
          <li className={activeTab === "home" ? "activeTab" : "nonActive"}>
            Home
          </li>
        </Link>
        <Link to={"/products"} className="link-styles">
          <li className={activeTab === "products" ? "activeTab" : "nonActive"}>
            {t("navProducts")}
          </li>
        </Link>
        <Link to={"/brands"} className="link-styles">
          <li className={activeTab === "brands" ? "activeTab" : "nonActive"}>
            {t("navBrands")}
          </li>
        </Link>
        <Link to={"/contact-us"} className="link-styles">
          <li
            className={activeTab === "contact-us" ? "activeTab" : "nonActive"}
          >
            {t("navContact")}
          </li>
        </Link>
        <div className="icons">
          <LanguageSelector />
          <div className="cartIcon">
            <CiShoppingCart onClick={() => setModal(!modal)} className="icon" />
            <h4>{productsCount}</h4>
          </div>
        </div>
      </ul>
      <div className="mobileIcons">
        <LanguageSelector />
        <div className="cartIcon">
          <CiShoppingCart onClick={() => setModal(!modal)} className="icon" />
          <h4>{productsCount}</h4>
        </div>
      </div>
      <div className={modal ? "modal" : "closedModal"}>
        <span>
          <div>
            <CiShoppingCart className="icon" />
            <h3>Shporta jote ({productsCount})</h3>
          </div>
          <FaTimes className="x" onClick={() => setModal(false)} />
        </span>
        <div className="items">
          {productsCount !== 0 ? (
            <div className="item">
              {cart.items.map((currentProduct, idx) => (
                <CartProduct
                  key={idx}
                  id={currentProduct.id}
                  quantity={currentProduct.quantity}
                  title={currentProduct.title}
                />
              ))}
            </div>
          ) : (
            <div className="emptyCart">
              <CiShoppingCart className="cartIcon" />
              <h3>Shporta juaj aktualisht është e zbrazet.</h3>
            </div>
          )}
        </div>
        {productsCount !== 0 ? (
          <>
            <h2 className="totalPrice">
              Çmimi total: {cart.getTotalCost().toFixed(2)} €
            </h2>
            <Link to={"/checkout"} className="link-styles">
              <button onClick={() => setModal(false)}>Checkout</button>
            </Link>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="mobile-menu" onClick={handleToggle}>
        {toggle ? <FaTimes className="icon" /> : <FaBars className="icon" />}
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 10px 7%;
  transition: 0.4s;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(10px);
  position: fixed;
  top: 0px;
  background: #ffffff5e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 4%;
  width: 100%;
  height: 80px;
  z-index: 100;
  box-sizing: border-box;
  .logo {
    width: 200px;
  }
  .closedModal {
    right: -200vw;
    position: fixed;
    transition: 0.4s;
  }
  .mobileIcons {
    display: none;
  }
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    overflow-y: scroll;
    width: 400px;
    background: white;
    max-width: 440px;
    color: #242424;
    padding: 2em 14px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px 2px var(--shadowColor);
    transition: 0.4s ease-in-out;
    .emptyCart {
      margin: 2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .cartIcon {
        font-size: 4em;
        margin: auto;
        text-align: center;
      }
      h3 {
        margin-top: 10px;
        font-weight: 300;
        text-align: center;
        color: #303030;
      }
    }
    span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;
      div {
        display: flex;
        align-items: center;
        gap: 1em;
        .icon {
          color: #242424;
          font-size: 2em;
        }
        h3 {
          font-weight: 300;
        }
      }
      .x {
        color: #242424;
        background: none;
        border: none;
        font-size: 1.7em;
        position: absolute;
        right: 1em;
        top: 5%;
      }
    }
    .items {
      margin-top: 2em;
      .item {
        width: 100%;
        display: flex;
        gap: 1em;
        flex-direction: column;
        justify-content: space-between;
        img {
          width: 120px;
          object-fit: contain;
        }
        span {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 90%;
          gap: 5px;
          h2 {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3 {
              font-size: 16px;
              font-weight: 400;
              width: 100%;
            }
            .icon {
              font-size: 1em;
              color: white;
            }
          }
          h4 {
            font-size: 15px;
            font-weight: 300;
            color: gray;
            line-height: 10px;
          }
          .quantity {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;
              margin-top: 0;
              button {
                display: flex;
                align-items: center;
                justify-content: center;
                align-self: flex-start;
                font-size: 30px;
                width: 30px;
                height: 30px;
                border: none;
                background: #292929;
                color: #ffffff;
                border-radius: 8px;
              }
              h1 {
                font-weight: 300;
              }
            }
          }
        }
      }
    }
    .totalPrice {
      font-weight: 400;
      margin: 1em;
      text-align: center;
    }
    button {
      background: #313131;
      border: none;
      margin: auto;
      display: flex;
      color: #ffffff;
      padding: 7px 14px;
      font-size: 18px;
      border-radius: 20px;
      font-family: "Poppins", cursive;
      cursor: pointer;
      transition: 0.4s;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
  }
  .nav-menu {
    display: flex;
    gap: 37px;
    list-style: none;
    align-items: center;
    li {
      color: var(--fontSecondaryColor);
      font-weight: 400;
      transition: 0.3s;
      cursor: pointer;
      :hover {
        color: var(--fontPrimaryColor);
      }
    }
    button {
      background: var(--fontPrimaryColor);
      border: none;
      color: var(--primaryBackgroundColor);
      padding: 7px 14px;
      font-size: 18px;
      border-radius: 20px;
      font-family: "Poppins", cursive;
      cursor: pointer;
      transition: 0.4s;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
    .activeTab {
      text-decoration: underline;
      text-underline-offset: 7px;
      font-weight: 600;
    }
    .icons {
      display: flex;
      align-items: center;
      gap: 10px;
      hr {
        height: 24px;
      }
      .cartIcon {
        display: flex;
        position: relative;
        .icon {
          font-size: 40px;
          cursor: pointer;
        }
        h4 {
          padding: 2px 3px;
          top: -14px;
          right: -10px;
          border-radius: 10px;
          position: absolute;
          background-color: #e4e4e4ce;
          font-weight: 300;
        }
      }
      .icon2 {
        font-size: 1.7em;
      }
    }
  }
  .nav-menu {
    display: flex;
    gap: 2em;
    list-style: none;
    align-items: center;
    li {
      color: var(--fontSecondaryColor);
      font-weight: 400;
      transition: 0.3s;
      cursor: pointer;
      :hover {
        color: var(--primaryColor);
      }
    }
    .lastLi {
      padding-right: 4em;
    }
    .activeTab {
      text-decoration: underline;
      text-underline-offset: 7px;
      font-weight: 600;
      color: var(--primaryColor);
    }
    .icons {
      display: flex;
      gap: 10px;
      align-items: center;
      .icon {
        font-size: 2.4em;
        color: var(--fontPrimaryColor);
        cursor: pointer;
        &:hover {
          color: var(--primaryColor);
        }
      }
    }
  }
  .toggleTheme {
    .themeIcon {
      font-size: 1.6em;
      cursor: pointer;
      color: var(--fontPrimaryColor);
    }
  }
  .mobile-menu {
    position: absolute;
    color: var(--fontPrimaryColor);
    right: 5%;
    font-size: 24px;
    cursor: pointer;
    transition: 0.3s;
    display: none;
    margin-top: 7px;
    :hover {
      transform: scale(1.1);
    }
  }
  @media (max-width: 1200px) {
    .mobileIcons {
      display: flex;
      position: absolute;
      right: 7em;
      align-items: center;
      gap: 4px;
      hr {
        height: 24px;
      }
      .cartIcon {
        display: flex;
        position: relative;
        .icon {
          font-size: 40px;
          cursor: pointer;
        }
        h4 {
          padding: 2px 3px;
          top: -10px;
          right: -5px;
          border-radius: 10px;
          position: absolute;
          background-color: #e4e4e4ce;
          font-weight: 300;
        }
      }
      .icon2 {
        font-size: 1.7em;
      }
    }
    .logo {
      width: 150px;
    }
    .nav-menu {
      width: 90%;
      height: 100vh;
      background: white;
      display: flex;
      flex-direction: column;
      padding: 10%;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0%;
      right: -100%;
      transition: 0.3s;
      z-index: 99;
      box-shadow: 0 0 12px 2px gray;
      .btns {
        flex-direction: column;
      }
      .lastLi {
        padding-right: 0;
      }
    }
    .mobile-menu {
      display: block;
      z-index: 99;
    }
    .nav-menu.active {
      right: 0%;
    }
  }
  @media (max-width: 700px) {
    .mobileIcons {
      right: 4.7em;
    }
  }
  @media (max-width: 450px) {
    .nav-menu {
      width: 98%;
    }
    .mobileIcons {
      right: 3.7em;
    }
    .modal {
      width: 99%;
    }
  }
`;
export default Navbar;
