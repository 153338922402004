import React from "react";
import styled from "styled-components";
import HomeBanner from "../components/homePageComponents/HomeBanner";
import ParfumeTypes from "../components/homePageComponents/ParfumeTypes";
import BestSellingProducts from "../components/homePageComponents/BestSellingProducts";
import Stats from "../components/homePageComponents/Stats";
import OfferOfTheWeek from "../components/homePageComponents/OfferOfTheWeek";
import FAQ from "../components/homePageComponents/FAQ";
import Brands from "../components/homePageComponents/Brands";
import ContactSection from "../components/homePageComponents/ContactSection";
import OptBanner from "../components/homePageComponents/OptBanner";
import BestSellingWomenProducts from "../components/homePageComponents/BestSellingWomenProducts";
import OffersBanner from "./OffersBanner";

const Home = () => {
  return (
    <Container>
      <OptBanner />
      <Stats />
      <ParfumeTypes />
      <Brands />
      <BestSellingProducts />
      <BestSellingWomenProducts />
      <OfferOfTheWeek />
      <FAQ />
      <ContactSection />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export default Home;
