import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import type1 from "../../assets/homePageAssets/type1.png";
import type2 from "../../assets/homePageAssets/type2.png";
import type3 from "../../assets/homePageAssets/type3.png";
import { useTranslation } from "react-i18next";
import { motion, useAnimation, useInView } from "framer-motion";

const ParfumeTypes = () => {
  const { t } = useTranslation();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);

  return (
    <Container>
      <div className="title">
        <h3>{t("typesH3")}</h3>
        <h1>{t("typesH1")}</h1>
        <p>{t("typesP")}</p>
      </div>
      <div className="types">
        <motion.img
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 90 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.3 }}
          src={type1}
          alt=""
        />
        <motion.img
          variants={{
            hidden: { opacity: 0, y: 90 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.6 }}
          src={type2}
          alt=""
        />
        <motion.img
          variants={{
            hidden: { opacity: 0, y: 90 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.9 }}
          src={type3}
          alt=""
        />
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 3em 7% 4em 7%;
  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    h3 {
      color: gray;
      font-weight: 300;
      font-family: "Indie Flower", cursive;
    }
    h1 {
      color: var(--primaryColor);
      font-weight: 400;
    }
    p {
      color: gray;
      font-weight: 300;
    }
  }
  .types {
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    flex-wrap: wrap;
    img {
      width: 27%;
      box-shadow: 3px 0 12px 2px #d4d4d4;
    }
  }
  @media (max-width: 900px) {
    .types {
      flex-wrap: wrap;
      img {
        width: 47%;
      }
    }
  }
  @media (max-width: 620px) {
    .types {
      flex-wrap: wrap;
      img {
        width: 90%;
      }
    }
  }
`;

export default ParfumeTypes;
