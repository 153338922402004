import React from "react";
import styled from "styled-components";
import pic1 from "../../assets/homePageAssets/statsPic1.webp";
import pic2 from "../../assets/homePageAssets/statsPic2.jpg";
import pic3 from "../../assets/homePageAssets/statsPic3.jpg";
import pic4 from "../../assets/homePageAssets/statsPic4.jpg";
import parfume4 from "../../assets/homePageAssets/adgImg.png";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const OptBanner = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="top">
        <motion.h3
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            delay: 0.4,
          }}
        >
          ARTIOLA DEVOLLI
        </motion.h3>
        <motion.h1
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            delay: 0.7,
          }}
        >
          {t("homeBannerTitle")}
        </motion.h1>
        {/* <motion.h1
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            delay: 1,
          }}
        >
          {t("homeBannerTitle2")}
        </motion.h1> */}
        <motion.p
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            delay: 1.3,
          }}
        >
          {t("homeBannerDesc")}
        </motion.p>
        <div className="btns">
          <Link to={"/products"} className="link-styles">
            <motion.button
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.3,
                delay: 1.6,
              }}
            >
              {t("homeBannerBtn")}
            </motion.button>
          </Link>
          <Link to={"/contact-us"} className="link-styles">
            <motion.button
              className="secondBtn"
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.3,
                delay: 1.9,
              }}
            >
              {t("homeBannerBtn2")}
            </motion.button>
          </Link>
        </div>
      </div>
      <div className="bottom">
        <motion.img
          animate={{ y: 0, opacity: 1 }}
          initial={{ y: 100, opacity: 0 }}
          transition={{
            type: "spring",
            delay: 2.1,
            duration: 1.4,
          }}
          className="leftPic"
          src={parfume4}
          alt=""
        />
        <div className="right">
          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 70, opacity: 0 }}
            transition={{
              type: "spring",
              delay: 2.3,
              duration: 1.4,
            }}
          >
            <img src={pic1} alt="" />
            <span>
              <h2>{t("homeBannerS1title")}</h2>
              <p>{t("homeBannerS1desc")}</p>
            </span>
          </motion.div>
          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 70, opacity: 0 }}
            transition={{
              type: "spring",
              delay: 2.5,
              duration: 1.4,
            }}
          >
            <img src={pic2} alt="" />
            <span>
              <h2>{t("homeBannerS2title")}</h2>
              <p>{t("homeBannerS2desc")}</p>
            </span>
          </motion.div>
          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 70, opacity: 0 }}
            transition={{
              type: "spring",
              delay: 2.7,
              duration: 1.4,
            }}
          >
            <img src={pic4} alt="" />
            <span>
              <h2>{t("homeBannerS3title")}</h2>
              <p>{t("homeBannerS3desc")}</p>
            </span>
          </motion.div>
          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 70, opacity: 0 }}
            transition={{
              type: "spring",
              delay: 2.9,
              duration: 1.4,
            }}
          >
            <img src={pic3} alt="" />
            <span>
              <span>
                <h2>{t("homeBannerS4title")}</h2>
                <p>{t("homeBannerS4desc")}</p>
              </span>
            </span>
          </motion.div>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 7%;
  /* background-image: var(--bgGradientLT); */
  .top {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1em;
    h3 {
      font-size: 17px;
      font-weight: 300;
      color: #206b88;
    }
    h1 {
      font-weight: 300;
      font-size: 10vw;
      line-height: 0.9em;
      color: #3d3d3d;
      font-family: "Alta", sans-serif;
    }
    p {
      color: gray;
      font-weight: 300;
    }
    .btns {
      display: flex;
      justify-content: center;
      gap: 1em;
      button {
        padding: 10px 24px;
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 300;
        border: none;
        border-radius: 30px;
        background: #1a1a1a;
        color: white;
        cursor: pointer;
        transition: 0.3s;
        box-shadow: 0 4px 12px 2px #b8b8b8;
        &:hover {
          background: var(--primaryColor);
          color: white;
        }
      }
      .secondBtn {
        border-radius: 0;
        background: none;
        color: #1a1a1a;
        border: none;
        box-shadow: 0 0 0 0;
        border-bottom: 2px solid #1a1a1a;
        &:hover {
          background: var(--primaryColor);
          color: white;
        }
      }
    }
  }
  .bottom {
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3em;
    .leftPic {
      width: 34%;
      min-width: 37%;
      min-height: 37%;
      border-radius: 100px;
      box-shadow: 0 4px 12px 3px #5e5e5e;
    }
    .right {
      display: flex;
      flex-direction: column;
      gap: 14px;
      width: 40%;
      div {
        margin-left: 3%;
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          object-fit: cover;
          box-shadow: 0 0 12px 2px #b9b9b9;
        }
        span {
          h2 {
            font-weight: 400;
            line-height: 0.8em;
            color: var(--primaryColor);
          }
          p {
            color: gray;
            font-weight: 300;
          }
        }
      }
    }
  }
  @media (max-width: 800px) {
    .bottom {
      flex-direction: column;
      .leftPic {
        width: 70%;
      }
      .right {
        flex-direction: row;
        width: 100%;
        div {
          margin-left: 0;
          flex-direction: column;
          align-items: center;
          text-align: center;
          h2 {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    .bottom {
      .right {
        flex-wrap: wrap;
        justify-content: center;
        div {
          width: 140px;
        }
      }
    }
  }
  @media (max-width: 520px) {
    .top {
      h1 {
        font-size: 12vw;
      }
    }
  }
  @media (max-width: 470px) {
    .top {
      h1 {
        font-size: 20vw;
      }
      .btns {
        gap: 7px;
        button {
          padding: 10px 17px;
        }
      }
    }
  }
`;

export default OptBanner;
