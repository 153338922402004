import React from "react";
import styled from "styled-components";

const OffersBanner = () => {
  return (
    <Container>
      <h1>VALENTINES OFFERS | 10.02 - 15.02</h1>
    </Container>
  );
};
const Container = styled.div`
  padding: 0em 4%;
  background-color: var(--primaryColor);
  top: 0;
  position: fixed;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #d35d5d;
  z-index: 99;
  h1 {
    color: white;
    font-weight: 300;
    font-size: 18px;
  }
  @media (max-width: 370px) {
    h1 {
      font-size: 17px;
    }
  }
`;

export default OffersBanner;
