import React from "react";
import styled from "styled-components";
import { CartContext } from "../CartContext";
import { FaTrash } from "react-icons/fa";
import { useContext } from "react";
import { getProductData } from "../components/productsData";

const CartProduct = (props) => {
  const cart = useContext(CartContext);
  const id = props.id;
  const quantity = props.quantity;
  const productData = getProductData(id);

  return (
    <Container>
      <img className="image" src={productData.image} alt="" />
      <span>
        <h2>
          <span className="cartPSpan">
            <h4>{productData.brand}</h4>
            <h3>{productData.productName}</h3>
          </span>
          <FaTrash
            onClick={() => cart.deleteFromCart(id)}
            className="deleteIcon"
          />
        </h2>
        <h4>{productData.size}ml</h4>
        <div className="quantity">
          <span>
            <button onClick={() => cart.removeOneFromCart(id)}>-</button>
            <h1>{quantity}</h1>
            <button onClick={() => cart.addOneToCart(id)}>+</button>
          </span>
          {productData.discountPrice ? (
            <div>
              <h3 className="dPrice">
                {(quantity * productData.discountPrice).toFixed(2)}€
              </h3>
            </div>
          ) : (
            <h3>{(quantity * productData.price).toFixed(2)}€</h3>
          )}
        </div>
      </span>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  .image {
    height: 120px;
    width: 120px;
    padding: 10px 0;
    object-fit: contain;
    border: 1px solid #d6d6d6;
    border-radius: 20px;
  }
  span {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1em;
    h2 {
      width: 100%;
      display: flex;
      span {
        display: flex;
      }
      .deleteIcon {
        color: #e69a9a;
        cursor: pointer;
      }
    }
    h4 {
      text-transform: capitalize;
      font-size: 17px;
      font-weight: 300;
      color: gray;
    }
  }
`;

export default CartProduct;
