import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import parfume1 from "../../assets/homePageAssets/myWay.jpg";
import parfume2 from "../../assets/homePageAssets/burberry.avif";
import parfume3 from "../../assets/homePageAssets/theOnlyOne.avif";
import parfume4 from "../../assets/homePageAssets/paradoxe.webp";
import rating from "../../assets/homePageAssets/rating.png";
import { FaShoppingCart } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";

const BestSellingWomenProducts = () => {
  const { t } = useTranslation();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="title">
        <h3>{t("bestSellingH3W")}</h3>
        <h1>{t("bestSellingH1W")}</h1>
        <p>{t("bestSellingPW")}</p>
      </div>
      <div className="items">
        <div className="item">
          <Link to={"/products/My%20Way"} className="link-styles">
            <motion.img
              ref={ref}
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 },
              }}
              initial="hidden"
              animate={animation}
              transition={{ duration: 0.4, delay: 0.3 }}
              className="perfumeImg"
              src={parfume1}
              alt=""
            />
          </Link>
          <h4>Giorgio Armani</h4>
          <h2 className="name">My Way</h2>
          <h3>
            <img src={rating} alt="" />
            (reviews)
          </h3>
          <span>
            <div>
              <span>
                <h3>€129.99</h3>
                <h2>€109.99</h2>
              </span>
            </div>
            <Link to={"/products/My%20Way"} className="link-styles">
              <button>{t("bestSellingBtn")}</button>{" "}
            </Link>
          </span>
        </div>
        <div className="item">
          <Link to={"/products/Goddess"} className="link-styles">
            <motion.img
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 },
              }}
              initial="hidden"
              animate={animation}
              transition={{ duration: 0.4, delay: 0.7 }}
              className="perfumeImg"
              src={parfume2}
              alt=""
            />
          </Link>
          <h4>Burberry</h4>
          <h2 className="name">Goddess</h2>
          <h3>
            <img src={rating} alt="" />
            (reviews)
          </h3>
          <span>
            <div>
              <span>
                <h3>€99.99</h3>
                <h2>€84.99</h2>
              </span>
            </div>
            <Link to={"/products/Goddess"} className="link-styles">
              <button>{t("bestSellingBtn")}</button>{" "}
            </Link>
          </span>
        </div>
        <div className="item">
          <Link to={"/products/My%20Only%20One"} className="link-styles">
            <motion.img
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 },
              }}
              initial="hidden"
              animate={animation}
              transition={{ duration: 0.4, delay: 1 }}
              className="perfumeImg"
              src={parfume3}
              alt=""
            />
          </Link>
          <h4>Dolche&Gabbana</h4>
          <h2 className="name">My Only One</h2>
          <h3>
            <img src={rating} alt="" />
            (reviews)
          </h3>
          <span>
            <div>
              <span>
                <h3>€149.90</h3>
                <h2>€124.99</h2>
              </span>
            </div>
            <Link to={"/products/My%20Only%20One"} className="link-styles">
              <button>{t("bestSellingBtn")}</button>{" "}
            </Link>
          </span>
        </div>
        <div className="item">
          <Link to={"/products/Paradoxe"} className="link-styles">
            <motion.img
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 },
              }}
              initial="hidden"
              animate={animation}
              transition={{ duration: 0.4, delay: 1.4 }}
              className="perfumeImg"
              src={parfume4}
              alt=""
            />
          </Link>
          <h4>Prada</h4>
          <h2 className="name">Paradoxe</h2>
          <h3>
            <img src={rating} alt="" />
            (reviews)
          </h3>
          <span>
            <div>
              <span>
                <h3>€134.90</h3>
                <h2>€116.99</h2>
              </span>
            </div>
            <Link to={"/products/Paradoxe"} className="link-styles">
              <button>{t("bestSellingBtn")}</button>{" "}
            </Link>
          </span>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    h3 {
      color: gray;
      font-weight: 300;
      font-family: "Indie Flower", cursive;
    }
    h1 {
      color: var(--primaryColor);
      font-weight: 400;
    }
    p {
      color: gray;
      font-weight: 300;
    }
  }
  .items {
    padding: 1em 7% 6em 7%;
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 4%;
    flex-wrap: wrap;
    .item {
      width: 20%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      text-align: center;
      .perfumeImg {
        width: 250px;
        height: 250px;
        object-fit: cover;
        border-radius: 30px;
        box-shadow: 4px 0 12px 4px #c4c4c4;
        transition: 0.4s;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
        }
      }
      .name {
        font-weight: 400;
        color: gray;
        font-size: 19px;
      }
      h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
        font-weight: 300;
        color: gray;
        img {
          width: 100px;
          height: 24px;
        }
      }
      span {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 90%;
        div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          gap: 7px;
          h3 {
            text-decoration: line-through;
          }
          h2 {
            font-weight: 500;
            font-size: 18px;
            color: var(--primaryColor);
            margin-left: 7px;
          }
        }
        button {
          background-color: #242424;
          color: white;
          padding: 10px;
          border-radius: 20px;
          cursor: pointer;
          transition: 0.3s;
          border: none;
          &:hover {
            background-color: var(--primaryColor);
          }
        }
      }
    }
    :nth-child(3) {
      img {
        height: 230px;
      }
    }
  }
  @media (max-width: 1200px) {
    .items {
      flex-wrap: wrap;
      .item {
        width: 40%;
      }
    }
  }
  @media (max-width: 700px) {
    .title {
      padding: 0 4%;
    }
    .items {
      padding: 2em 4%;
      gap: 2em;
      margin-top: 1em;
      .item {
        img {
          height: 200px;
        }
      }
    }
  }
  @media (max-width: 620px) {
    .items {
      flex-direction: column;
      align-items: center;
      .item {
        .perfumeImg {
          width: 90%;
          height: auto;
        }
      }
    }
  }
  @media (max-width: 550px) {
    .items {
      gap: 2em;
      .item {
        width: 80%;
        img {
          height: 200px;
        }
      }
    }
  }
`;

export default BestSellingWomenProducts;
