import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SuccessPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Porosia u bë me sukses | Coolest Parfume";
  }, []);
  return (
    <Container>
      <h1>Porosia u perfundua me sukses!</h1>
      <p>Faleminderit per porosinë tuaj dhe do te vij brenda pak ditesh :)</p>
      <Link to={"/products"}>
        <button>SHIKO TJERA PRODUKTE</button>
      </Link>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 5%;
  text-align: center;
  h1 {
    font-family: "The Seasons";
    font-size: 5em;
    font-weight: 200;
  }
  p {
    color: gray;
  }
  button {
    margin: auto;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    background: white;
    color: #777777;
    border: none;
    padding: 15px 24px;
    cursor: pointer;
    transition: 0.4s;
    margin: 1em;
    border: 1px solid #c7c7c7;
    &:hover {
      background: var(--primaryColor);
      color: white;
    }
  }
  @media (max-width: 450px) {
    h1 {
      font-size: 16vw;
    }
  }
`;

export default SuccessPage;
