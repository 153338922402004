import React from "react";
import styled from "styled-components";
import bannerImg from "../../assets/homePageAssets/bannerImg2.png";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const HomeBanner = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="left">
        <motion.h3
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            delay: 0.4,
          }}
        >
          ARTIOLA DEVOLLI
        </motion.h3>
        <motion.h1
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            delay: 0.7,
          }}
        >
          {t("homeBannerTitle")}
        </motion.h1>
        <motion.p
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            delay: 1,
          }}
        >
          {t("homeBannerDesc")}
        </motion.p>
        <motion.button
          animate={{ x: 0, opacity: 1 }}
          initial={{ X: 100, opacity: 0 }}
          transition={{
            duration: 0.7,
            bounce: 0.3,
            delay: 1.4,
            type: "spring",
          }}
        >
          {t("homeBannerBtn")}
        </motion.button>
      </div>
      <motion.img
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: -70 }}
        transition={{
          duration: 0.7,
          bounce: 0.3,
          delay: 2,
          type: "spring",
        }}
        src={bannerImg}
        alt=""
      />
    </Container>
  );
};
const Container = styled.div`
  padding: 5.7em 10% 0.7em 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: var(--bgGradient);
  img {
    width: 47%;
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
    width: auto;
    h3 {
      font-size: 17px;
      font-weight: 300;
      color: skyblue;
    }
    h1 {
      font-weight: 400;
      font-size: 4.7vw;
      line-height: 0.9em;
      color: #3d3d3d;
      font-family: "Montserrat", sans-serif;
    }
    p {
      color: gray;
    }
    button {
      padding: 10px 24px;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      font-weight: 300;
      border: none;
      border-radius: 30px;
      background: var(--primaryColor);
      color: white;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background: gray;
        color: white;
      }
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 2em;
    .left {
      align-items: center;
      text-align: center;
      h1 {
        line-height: 1em;
        font-size: 9vw;
      }
      h3 {
        color: #1186b4;
      }
    }
    img {
      width: 80%;
    }
  }
  @media (max-width: 550px) {
    .left {
      h1 {
        font-size: 12vw;
      }
    }
  }
`;

export default HomeBanner;
