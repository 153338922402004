import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { FaTruck, FaHandshake } from "react-icons/fa";
import { IoIosPricetags } from "react-icons/io";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { motion, useAnimation, useInView } from "framer-motion";

const Stats = () => {
  const { t } = useTranslation();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);

  return (
    <Container>
      <motion.div
        ref={ref}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.3 }}
      >
        <FaHandshake className="icon" />
        <span>
          <h2>{t("statsMain1")}</h2>
          <h3>{t("statsSec1")}</h3>
        </span>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.6 }}
      >
        <FaTruck className="icon" />
        <span>
          <h2>{t("statsMain2")}</h2>
          <h3>{t("statsSec2")}</h3>
        </span>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.9 }}
      >
        <MdOutlineWorkspacePremium className="icon" />
        <span>
          <h2>{t("statsMain3")}</h2>
          <h3>{t("statsSec3")}</h3>
        </span>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 1.2 }}
      >
        <IoIosPricetags className="icon" />
        <span>
          <h2>{t("statsMain4")}</h2>
          <h3>{t("statsSec4")}</h3>
        </span>
      </motion.div>
    </Container>
  );
};
const Container = styled.div`
  padding: 0px 7% 2em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  flex-wrap: wrap;
  div {
    display: flex;
    justify-content: center;
    gap: 1em;
    padding: 20px;
    border-radius: 30px;
    .icon {
      font-size: 3em;
      color: var(--primaryColor);
    }
    h2 {
      font-weight: 500;
      font-size: 20px;
    }
    h3 {
      font-size: 17px;
      font-weight: 300;
      color: gray;
    }
  }
`;

export default Stats;
