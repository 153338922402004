import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../context/AuthContext";
import { db } from "../firebase";
import { updateDoc, doc, onSnapshot } from "firebase/firestore";
import { Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa6";

const SavedProducts = () => {
  const [products, setProducts] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "users", `${currentUser?.email}`),
      (doc) => {
        setProducts(doc.data()?.savedProducts);
      }
    );
    return () => unsubscribe();
  }, [currentUser?.email]);

  useEffect(() => {
    onSnapshot(doc(db, "users", `${currentUser?.email}`), (doc) => {
      setProducts(doc.data()?.savedProducts);
    });
  }, [currentUser?.email]);

  const productRef = doc(db, "users", `${currentUser?.email}`);

  const deleteProduct = async (passedID) => {
    try {
      const result = products.filter((item) => item.productId !== passedID);
      await updateDoc(productRef, {
        savedProducts: result,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <div className="title">
        <h2>Your Saved Perfumes</h2>
        <p>
          To save a perfume, go to{" "}
          <Link className="link" to={"/products"}>
            Products Page
          </Link>
        </p>
      </div>
      <div className="products">
        {products.map((item) => (
          <div className="product" key={item.id}>
            <Link to={`/products/${item.productName}`}>
              <img src={item.image} alt="" />
            </Link>
            <h4>{item.brand}</h4>
            <Link to={`${item.productName}`} className="link-styles">
              <h3>{item.productName}</h3>
            </Link>
            <span>
              <Link
                to={`/products/${item.productName}`}
                className="link-styles"
              >
                <button>SEE MORE</button>
              </Link>
              <FaTrash
                className="icon"
                onClick={() => deleteProduct(item.productId)}
              />
            </span>
          </div>
        ))}
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 4%;
  font-family: "Montserrat", sans-serif;
  .title {
    margin-bottom: 2em;
    text-align: center;
    h2 {
      font-weight: 500;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
    .link {
      color: var(--primaryColor);
    }
  }
  .products {
    margin-top: 5em;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 4em 2em;
    flex-wrap: wrap;
    .product {
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        height: 200px;
      }
      h4 {
        font-weight: 300;
        color: gray;
        margin: 10px 0 3px 0;
      }
      h3 {
        font-weight: 500;
        color: var(--primaryColor);
      }
      span {
        margin-top: 10px;
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        button {
          padding: 10px 24px;
          font-family: "Poppins", sans-serif;
          font-size: 18px;
          font-weight: 300;
          text-transform: uppercase;
          border: none;
          border-radius: 30px;
          background: var(--primaryColor);
          color: white;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            background: #1f1f1f;
            color: white;
          }
        }
        .icon {
          display: flex;
          align-items: center;
          font-size: 2em;
          cursor: pointer;
          transition: 0.3s;
          color: #bb4040;
          &:hover {
            color: var(--primaryColor);
          }
        }
      }
    }
  }
`;

export default SavedProducts;
