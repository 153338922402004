import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import image from "../../assets/homePageAssets/OFWimg.jpg";

const OfferOfTheWeek = () => {
  const { t } = useTranslation();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  // const videoRef = useRef(null);

  // useEffect(() => {
  //   // Play the video when the component mounts
  //   videoRef.current.play();
  // }, []);

  // const handleEnded = () => {
  //   // When the video ends, restart it
  //   videoRef.current.currentTime = 0;
  //   videoRef.current.play();
  // };

  return (
    <Container>
      <motion.img
        ref={ref}
        variants={{
          hidden: { x: -90, opacity: 0 },
          visible: { x: 0, opacity: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.3 }}
        src={image}
        alt=""
      />
      {/* <video
        ref={videoRef}
        autoPlay
        muted
        loop
        onEnded={handleEnded}
        width="100%"
        height="auto"
      >
        <source src={video} type="video/webm" />
      </video> */}

      <div
        className="right"
        variants={{
          hidden: { y: 90, opacity: 0 },
          visible: { y: 0, opacity: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.3 }}
      >
        <h1>{t("weekOffer")}</h1>
        <h4>Giorgio Armani</h4>
        <h2>My Way</h2>
        <h3>90ml</h3>
        <span>
          <h4>€129.99</h4>
          <h2>€109.99</h2>
        </span>
        <p>{t("wOfferDesc")}</p>
        <div className="tags">
          <p>{t("wOfferIng1")}</p>
          <p>{t("wOfferIng2")}</p>
          <p>{t("wOfferIng3")}</p>
          <p>{t("wOfferIng4")}</p>
        </div>
        <Link to={"/products/MYSLF"} className="link-styles">
          <button>{t("productsButton")}</button>
        </Link>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3em;
  video {
    width: 30%;
    border-radius: 30%;
    box-shadow: 0 7px 12px 2px #818181;
  }
  img {
    width: 40%;
    border-radius: 30%;
    box-shadow: 0 7px 12px 2px #818181;
  }
  .right {
    display: flex;
    flex-direction: column;
    h1 {
      font-weight: 400;
      color: var(--primaryColor);
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(17deg, #74b9fa 23%, #7009b4 77%);
      -webkit-background-clip: text;
      background-clip: text;
    }
    h4 {
      color: gray;
      font-weight: 500;
      margin-top: 10px;
    }
    h3 {
      color: gray;
      font-weight: 500;
      margin-top: 10px;
    }
    h2 {
      color: var(--primaryColor);
      font-weight: 400;
    }
    span {
      margin: 1em;
      display: flex;
      align-items: center;
      gap: 1em;
      h4 {
        font-weight: 300;
        text-decoration: line-through;
      }
      h2 {
        margin-top: 7px;
      }
    }
    p {
      color: gray;
      font-weight: 300;
      max-width: 600px;
    }
    .tags {
      display: flex;
      align-items: center;
      gap: 1em;
      margin-top: 1em;
      p {
        background-color: #f1f1f1;
        padding: 10px;
        border-radius: 20px;
        box-shadow: 0 4px 12px 2px #b8b8b8;
      }
    }
    button {
      margin-top: 10px;
      padding: 10px 18px;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      font-weight: 300;
      border: none;
      align-self: flex-start;
      border-radius: 30px;
      background-color: #181717;
      cursor: pointer;
      color: white;
      transition: 0.3s;
      box-shadow: 0 4px 12px 2px #b8b8b8;
      &:hover {
        background-color: var(--primaryColor);
        color: white;
      }
    }
  }
  @media (max-width: 1000px) {
    padding: 4em 4%;
  }
  @media (max-width: 800px) {
    padding: 4em 7%;
    flex-direction: column;
    img {
      width: 87%;
      object-fit: cover;
      border-radius: 40px;
    }
    .right {
      .tags {
        flex-wrap: wrap;
      }
    }
  }
`;

export default OfferOfTheWeek;
