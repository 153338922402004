import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useAuth } from "../context/AuthContext";
import image from "../assets/smallPageAssets/signUpImage.png";
import { useTranslation } from "react-i18next";

const SignUp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Sign Up Page | Coolest Parfume";
  }, []);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const [visibility, setVisibility] = useState(false);

  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }
    if (passwordRef.current.length > 6) {
      return setError("Passwords must be longer than 6 characters!");
    }
    try {
      setMessage("");
      setError("");
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      navigate("/");
      setMessage("You're signed up. Login now!");
    } catch {
      setError("Failed to create an account");
    }
    setLoading(false);
  }
  return (
    <Container>
      <img src={image} alt="" />
      <motion.form
        method="POST"
        onSubmit={handleSubmit}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{
          duration: 0.7,
          bounce: 0.3,
          delay: 0.8,
          type: "spring",
        }}
      >
        <h1>{t("signUpTitle")}</h1>
        <input type="name" placeholder={t("signUpName")} />
        <input type="email" ref={emailRef} placeholder={t("signUpEmail")} />
        <div>
          <input
            ref={passwordRef}
            type={visibility === true ? "text" : "password"}
            placeholder={t("signUpPassword")}
          />
          {visibility === true ? (
            <FaLockOpen
              onClick={() => setVisibility(!visibility)}
              className="icon"
            />
          ) : (
            <FaLock
              onClick={() => setVisibility(!visibility)}
              className="icon"
            />
          )}
        </div>
        <div>
          <input
            ref={passwordConfirmRef}
            type={visibility === true ? "text" : "password"}
            placeholder={t("signUpConfirmPassword")}
          />
          {visibility === true ? (
            <FaLockOpen
              onClick={() => setVisibility(!visibility)}
              className="icon"
            />
          ) : (
            <FaLock
              onClick={() => setVisibility(!visibility)}
              className="icon"
            />
          )}
        </div>
        {error && <div className="failAlert alert">{error}</div>}
        {message && <div className="successAlert alert">{message}</div>}
        <div className="haveAcc">
          <p>{t("signUpHaveAcc")}</p>
          <Link to={"/log-in"}>
            <h3>{t("signUpLogIn")}</h3>
          </Link>
        </div>
        {/* <div className="signUpOptions">
          or Sign Up with -
          <span>
            <FcGoogle className="googleIcon" />
          </span>
        </div> */}
        <button type="submit" disabled={loading}>
          {t("navBtn")}
        </button>
      </motion.form>
    </Container>
  );
};
const Container = styled.div`
  padding: 7% 7%;
  background: var(--backgroundGradient);
  color: var(--fontPrimaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  position: relative;
  img {
    width: 50%;
    border-radius: 40px;
    max-width: 700px;
    box-shadow: 0 4px 12px 2px #b8b8;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 500px;
    text-align: center;
    height: 480px;
    padding: 30px;
    h1 {
      font-weight: 500;
      color: var(--fontSecondaryColor);
      font-family: "Comfortaa", cursive;
    }
    .alert {
      text-align: center;
      color: white;
      background: #ff000086;
      display: flex;
      justify-content: center;
      width: auto;
      padding: 10px;
      border-radius: 10px;
      margin-top: 10px;
    }
    .successAlert {
      background: #0aaa0a92;
    }
    input {
      background: var(--primaryBackgroundColor);
      margin-top: 12px;
      border-radius: 20px;
      border: 2px solid transparent;
      padding: 14px 20px;
      font-family: "Poppins", sans-serif;
      outline: none;
      font-size: 16px;
      width: 100%;
      box-shadow: 7px 7px 12px var(--shadowColor1),
        -4px -4px 12px var(--shadowColor2);
      color: var(--fontPrimaryColor);
      ::placeholder {
        color: var(--fontSecondaryColor);
        font-weight: 300;
      }
    }
    div {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      .icon {
        font-size: 24px;
        right: 4%;
        top: 1.2em;
        position: absolute;
        cursor: pointer;
        color: var(--fontSecondaryColor);
      }
    }
    button {
      padding: 10px 18px;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      font-weight: 300;
      border: none;
      border-radius: 30px;
      background-color: #181717;
      cursor: pointer;
      color: white;
      transition: 0.3s;
      box-shadow: 0 4px 12px 2px #b8b8;
      &:hover {
        background-color: var(--primaryColor);
        color: white;
      }
    }
    .haveAcc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em 2%;
      p {
        color: var(--fontSecondaryColor);
      }
      h3 {
        color: var(--fontSecondaryColor);
        font-weight: 400;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .signUpOptions {
      display: flex;
      justify-content: center;
      gap: 1em;
      align-items: center;
      .googleIcon {
        font-size: 2em;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 970px) {
    padding: 7em 3%;
  }
  @media (max-width: 1230px) {
    .dots {
      right: 30%;
    }
  }
  @media (max-width: 850px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 700px) {
    img {
      width: 80%;
    }
    .dots {
      top: 10%;
    }
  }
  @media (max-width: 500px) {
    padding: 7em 1%;
    img {
      width: 80%;
    }
    form {
      width: 100%;
      padding: 10px;
    }
    .dots {
      width: 100px;
    }
  }
`;

export default SignUp;
