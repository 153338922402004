import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../context/AuthContext";
import { db } from "../firebase";
import { updateDoc, doc, onSnapshot } from "firebase/firestore";
import { Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa6";

const WishlistPage = () => {
  const [products, setProducts] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "users", `${currentUser?.email}`),
      (doc) => {
        setProducts(doc.data()?.savedProducts);
      }
    );
    return () => unsubscribe();
  }, [currentUser?.email]);

  useEffect(() => {
    onSnapshot(doc(db, "users", `${currentUser?.email}`), (doc) => {
      setProducts(doc.data()?.savedProducts);
    });
  }, [currentUser?.email]);

  const productRef = doc(db, "users", `${currentUser?.email}`);

  const deleteProduct = async (passedID) => {
    try {
      const result = products.filter((item) => item.productId !== passedID);
      await updateDoc(productRef, {
        savedProducts: result,
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container>
      <div className="products">
        {products.map((item) => (
          <div className="product" key={item.id}>
            <Link to={`/products/${item.productName}`}>
              <img src={item.image} alt="" />
            </Link>
            <h4>{item.brand}</h4>
            <Link to={`${item.productName}`} className="link-styles">
              <h3>{item.productName}</h3>
            </Link>
            <span>
              <Link
                to={`/products/${item.productName}`}
                className="link-styles"
              >
                <button>SEE MORE</button>
              </Link>
              <FaTrash
                className="icon"
                onClick={() => deleteProduct(item.productId)}
              />
            </span>
          </div>
        ))}
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 7%;
`;

export default WishlistPage;
