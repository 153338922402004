import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { productsData } from "../components/productsData";
import stars from "../assets/homePageAssets/rating.png";
import { motion } from "framer-motion";
import { FaCheckCircle } from "react-icons/fa";
import { CartContext } from "../CartContext";
import { useNavigate } from "react-router-dom";

const ProductPage = () => {
  const { t } = useTranslation();
  const { productName } = useParams();
  const cart = useContext(CartContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Coolest Parfume";
  }, []);

  const buyNow = (product) => {
    cart.addOneToCart(product.id);
    navigate("/checkout");
  };

  return (
    <Container>
      {productsData
        .filter((item) => item.productName === productName)
        .map((product) => (
          <>
            <motion.div
              animate={{ y: 0, opacity: 1 }}
              initial={{ y: 100, opacity: 0 }}
              transition={{
                type: "spring",
                delay: 0.4,
                duration: 1,
              }}
              className="img"
            >
              <img src={product.image} alt="" />
              {product?.discountPrice && <span>{t("sale")}</span>}
            </motion.div>
            <motion.div
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                type: "spring",
                delay: 0.7,
                duration: 1,
              }}
              className="right"
            >
              <img src={stars} alt="" />
              <h4>{product.brand}</h4>
              <h2>{product.productName}</h2>
              <h4>{product.size}ml</h4>
              {product?.discountPrice ? (
                <span>
                  <h2 className="nPrice">{product.price}€</h2>
                  <h1 className="dPrice">{product?.discountPrice}€</h1>
                </span>
              ) : (
                <h1>{product.price}€</h1>
              )}
              <h3>
                {t("productPageAvail")}: <FaCheckCircle className="icon" />
              </h3>
              <p>{product.description}</p>
              <div className="btns">
                <button onClick={() => buyNow(product)}>BLEJ TANI</button>
                <button onClick={() => cart.addOneToCart(product.id)}>
                  SHTO NE SHPORTE
                </button>
              </div>
            </motion.div>
          </>
        ))}
    </Container>
  );
};
const Container = styled.div`
  padding: 8em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3em;
  background-image: var(--bgGradientLT);
  .img {
    width: 40%;
    box-shadow: 7px 7px 24px #b5b5b5, -7px -7px 24px #ffffff;
    padding: 20px;
    border-radius: 30px;
    background: white;
    position: relative;
    span {
      position: absolute;
      top: 2em;
      right: 0;
      background: var(--primaryColor);
      padding: 10px 20px;
      border-radius: 10px 0 0 10px;
      color: white;
    }
    img {
      width: 100%;
      object-fit: contain;
      max-height: 70vh;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    img {
      width: 100px;
    }
    h1 {
      font-weight: 400;
      color: var(--primaryColor);
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(17deg, #74b9fa 23%, #7009b4 77%);
      -webkit-background-clip: text;
      background-clip: text;
    }
    h4 {
      color: gray;
      font-weight: 500;
      margin-top: 10px;
    }
    h2 {
      color: var(--primaryColor);
      font-weight: 400;
      margin-top: 7px;
    }
    h3 {
      display: flex;
      align-items: center;
      font-weight: 300;
      color: gray;
      margin-bottom: 10px;
      .icon {
        color: green;
        margin-left: 1em;
      }
    }
    span {
      margin: 1em;
      display: flex;
      align-items: center;
      gap: 1em;
      .nPrice {
        color: gray;
        font-weight: 300;
        text-decoration: line-through;
      }
    }
    p {
      color: gray;
      font-weight: 300;
      max-width: 600px;
    }
    .tags {
      display: flex;
      align-items: center;
      gap: 1em;
      margin-top: 1em;
      p {
        background-color: #f1f1f1;
        padding: 10px;
        border-radius: 20px;
      }
    }
    .btns {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 1em;
      button {
        padding: 10px 24px;
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 300;
        border: none;
        background: #333;
        color: white;
        cursor: pointer;
        transition: 0.3s;
        box-shadow: 0 4px 12px 2px #b8b8b8;
        &:hover {
          background: #52ce67;
          color: white;
        }
      }
      :nth-child(2) {
        background-color: #ffffff;
        color: #333;
        box-shadow: 0 4px 12px 2px #e2e2e2;
      }
      .icon {
        display: flex;
        align-items: center;
        font-size: 2em;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          color: var(--primaryColor);
        }
      }
    }
  }
  @media (max-width: 1000px) {
    padding: 8em 4%;
  }
  @media (max-width: 800px) {
    padding: 8em 7%;
    flex-direction: column;
    .img {
      width: 87%;
      margin: auto;
      display: flex;
      justify-content: center;
      img {
        width: 100%;
        max-width: 400px;
        object-fit: cover;
      }
    }
    .right {
      .tags {
        flex-wrap: wrap;
      }
    }
  }
  @media (max-width: 600px) {
    .right {
      .tags {
        justify-content: center;
      }
    }
  }
`;

export default ProductPage;
