import React from "react";
import styled from "styled-components";
import ea from "../../assets/homePageAssets/eaLogo.png";
import ysl from "../../assets/homePageAssets/yslLogo.png";
import dior from "../../assets/homePageAssets/diorLogo.png";
import prada from "../../assets/homePageAssets/pradaLogo.png";
import gucci from "../../assets/homePageAssets/gucciLogo.png";

const Brands = () => {
  return (
    <Container>
      <img src={ea} alt="" />
      <img src={ysl} alt="" />
      <img src={dior} alt="" />
      <img src={prada} alt="" />
      <img src={gucci} alt="" />
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3em;
  flex-wrap: wrap;
  img {
    height: 1.7vw;
    opacity: 0.4;
  }
  :nth-child(2) {
    height: 3vw;
  }
  @media (max-width: 780px) {
    img {
      height: 3vw;
    }
    :nth-child(2) {
      height: 4vw;
    }
  }
  @media (max-width: 500px) {
    gap: 2em;
    img {
      height: 4vw;
    }
    :nth-child(2) {
      height: 5vw;
    }
  }
`;

export default Brands;
