import React, { useEffect } from "react";
import styled from "styled-components";
import b1 from "../assets/homePageAssets/brands/b1.png";
import b2 from "../assets/homePageAssets/brands/b2.png";
import b3 from "../assets/homePageAssets/brands/b3.png";
import b4 from "../assets/homePageAssets/brands/b4.png";
import b5 from "../assets/homePageAssets/brands/b5.png";
import b6 from "../assets/homePageAssets/brands/b6.png";
import b7 from "../assets/homePageAssets/brands/b7.png";
import b8 from "../assets/homePageAssets/brands/b8.png";
import b9 from "../assets/homePageAssets/brands/b9.png";
import b10 from "../assets/homePageAssets/brands/b10.png";
import b11 from "../assets/homePageAssets/brands/b11.png";
import b12 from "../assets/homePageAssets/brands/b12.png";
import b13 from "../assets/homePageAssets/brands/b13.png";
import b14 from "../assets/homePageAssets/brands/b14.png";
import { useTranslation } from "react-i18next";

const BrandsPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Brands | Coolest Parfume";
  }, []);
  return (
    <Container>
      <h1>{t("brandsTitle")}</h1>
      <div className="items">
        <img src={b1} alt="" />
        <img src={b2} alt="" />
        <img src={b3} alt="" />
        <img src={b4} alt="" />
        <img src={b5} alt="" />
        <img src={b6} alt="" />
        <img src={b7} alt="" />
        <img src={b8} alt="" />
        <img src={b9} alt="" />
        <img src={b10} alt="" />
        <img src={b11} alt="" />
        <img src={b12} alt="" />
        <img src={b13} alt="" />
        <img src={b14} alt="" />
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 7%;
  h1 {
    font-size: 7vw;
    text-align: center;
    font-weight: 200;
  }
  .items {
    margin-top: 3em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 3em;
    img {
      height: 100px;
      width: auto;
      max-width: 200px;
      object-fit: contain;
      opacity: 0.5;
    }
  }
`;

export default BrandsPage;
