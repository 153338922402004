import { Navigate, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./routes/Home";
import Footer from "./components/Footer";
import BrandsPage from "./routes/BrandsPage";
import Products from "./routes/Products";
import ProductPage from "./routes/ProductPage";
import SignUp from "./routes/SignUp";
import { AuthProvider } from "./context/AuthContext";
import LogIn from "./routes/LogIn";
import AccountPage from "./routes/AccountPage";
import ContactSection from "./components/homePageComponents/ContactSection";
import PrivateRoute from "./routes/PrivateRoute";
import ContactPage from "./routes/ContactPage";
import WishlistPage from "./routes/WishlistPage";
import NotFoundPage from "./routes/NotFoundPage";
import OffersBanner from "./routes/OffersBanner";
import ReactGA from "react-ga";
import CartProvider from "./CartContext";
import Checkout from "./routes/Checkout";
import SuccessPage from "./routes/SuccessPage";

function App() {
  ReactGA.initialize("G-F4TG5MW1J9 ");
  return (
    <AuthProvider>
      <CartProvider>
        <div className="App">
          {/* <OffersBanner /> */}

          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/brands" element={<BrandsPage />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/log-in" element={<LogIn />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/success-page" element={<SuccessPage />} />
            <Route
              path="/account"
              element={
                <PrivateRoute>
                  <AccountPage />
                </PrivateRoute>
              }
            />

            <Route path="/products/:productName" element={<ProductPage />} />

            <Route path="*" element={<NotFoundPage />} />
            <Route path="/404" element={<Navigate to="/404" />} />
          </Routes>
          <Footer />
        </div>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
