import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { productsData } from "../components/productsData";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import Product from "../components/productsPageComponents/Product";

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Products | Coolest Parfume";
  }, []);

  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredProducts = productsData.filter(
    (item) =>
      item.productName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.brand.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container>
      <div className="title">
        <motion.h3
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.4 }}
        >
          {t("productsTitleH3")}
        </motion.h3>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.7 }}
        >
          {t("productsTitleH1")}
        </motion.h1>
      </div>
      <div className="searchBar">
        <motion.input
          animate={{ y: 0, opacity: 1 }}
          initial={{ y: 100, opacity: 0 }}
          transition={{
            type: "spring",
            delay: 1,
            duration: 1,
          }}
          type="search"
          placeholder={t("productsSearch")}
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      {searchQuery.length > 0 && filteredProducts.length > 0 && (
        <AnimatePresence>
          <motion.div
            className="products"
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
          >
            {filteredProducts.map((item) => (
              <Product item={item} />
            ))}
          </motion.div>
        </AnimatePresence>
      )}

      <motion.div layout className="products">
        {searchQuery.length === 0 &&
          productsData.map((item) => (
            <AnimatePresence layout>
              <Product item={item} />
            </AnimatePresence>
          ))}
      </motion.div>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 7%;
  .title {
    text-align: center;
    h3 {
      font-weight: 300;
      color: gray;
    }
    h1 {
      font-weight: 400;
      font-size: 3vw;
    }
  }
  .searchBar {
    display: flex;
    justify-content: center;
    margin: 2em;
    input {
      border: none;
      outline: none;
      font-family: "Montserrat", sans-serif;
      font-size: 19px;
      color: #383838;
      padding: 14px 24px;
      box-shadow: 0 4px 12px 2px #b8b8b8;
      border-radius: 30px;
      width: 500px;
      ::placeholder {
        color: #a52c2c;
      }
    }
  }
  .products {
    margin-top: 5em;
    display: flex;
    justify-content: center;
    /* align-items: flex-end; */
    gap: 4em 0;
    flex-wrap: wrap;
    .product {
      padding: 0 20px;
      width: 270px;
      height: 350px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: left;
      img {
        height: 150px;
        align-self: center;
        justify-self: center;
        width: 100%;
        object-fit: contain;
        margin: auto;
        text-align: center;
        &:hover {
          transform: scale(1.1);
        }
      }
      h4 {
        font-weight: 300;
        text-align: left;
        margin: 10px 0 3px 0;
        color: var(--primaryColor);
      }
      h3 {
        color: gray;
        font-weight: 500;
      }
      .priceDiv {
        span {
          display: flex;
          justify-content: flex-start;
          gap: 10px;
          h2 {
            text-decoration: line-through;
            color: gray;
            font-weight: 300;
            font-size: 17px;
          }
          h1 {
            font-weight: 500;
            font-size: 19px;
          }
        }
      }
      span {
        /* margin-top: 10px; */
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        text-align: left;
        button {
          padding: 5px 24px;
          font-family: "Poppins", sans-serif;
          font-size: 18px;
          font-weight: 300;
          text-transform: uppercase;
          border: none;
          border-radius: 10px;
          background: #333;
          color: white;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            background: var(--primaryColor);
            color: white;
          }
        }
        .icon {
          display: flex;
          align-items: center;
          font-size: 2em;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            color: var(--primaryColor);
          }
        }
      }
    }
  }
  @media (max-width: 740px) {
    .products {
      .product {
        width: 100%;
        align-items: center;
        img {
          display: flex;
          justify-content: center;
          width: 100%;
        }
        h3 {
          font-size: 17px;
        }
        span {
          button {
            font-size: 14px;
            padding: 10px 14px;
          }
          .icon {
            font-size: 1.3em;
          }
        }
        .priceDiv {
          align-self: flex-start;
          justify-self: flex-start;
          span {
            justify-content: start;
          }
        }
      }
    }
  }
  @media (max-width: 560px) {
    padding: 7em 1%;
    .products {
      .product {
        padding: 5px 1px;
        h4 {
          font-size: 12px;
          padding-top: 7px;
        }
        h3 {
          font-size: 14px;
        }
        h2 {
          font-size: 16px;
        }
        span {
          justify-content: space-between;
        }
      }
    }
  }
`;

export default Products;
